
export const nullDate = new Date("1970-01-01T00:00:00+00:00");

const _baseUrl = "/api/v1";

export const baseUrl = _baseUrl;
export const recaptchaToken = "6LdLfscUAAAAAIKKy1fWWY6t5xyo4sfwgSYq3pJG";

export const competitionVersion = 2022;
export const discordLink = "https://discord.gg/phyqdh6";
export const pricesList = [3200, 1500, 1000];

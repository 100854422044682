import * as React from "react";

import Footer from "@components/Footer";
import Link from "@components/Link";

import "@styles/rules.scss";

export const FaqPage: React.FunctionComponent = ( ) => (
    <div className={"page rules"}>
        <div className={"inner"}>
            <h1 className={"mainTitle"}>FAQ</h1>

            <ol>
                <li><b>How to change the password to the account?</b>
                <br/>You can change your password using the <Link href="/recover/init" title={`"lost password recovery"`} child={`"lost password recovery"`}/>.</li>
                <li><b>How to change my e-mail address?</b>
                <br/>To change your e-mail address, send a request to the contact e-mail: <a href="mailto:justcatthefish+2022@gmail.com">justcatthefish+2022@gmail.com</a>. Mail must be sent from the e-mail address matching the one currently used by your account.</li>
                <li><b>How to add a person to the team?</b>
                <br/>As according to our <Link href="/rules" title={"rules"} child={"rules"}/> a team should own just a single account and share it with their members.</li>
                <li><b>I want to play with a team, but I am not a member of any.</b>
                <br/>Join our <a href="https://discord.gg/phyqdh6">Discord and use the #looking-for-team</a> channel!</li>
            </ol>

            <Footer />
        </div>
    </div>
);
